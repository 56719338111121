/* DESKTOP */
@media screen and (min-width: 1200px) {
    .intro-section {
        /* display side by side */
        display: flex;
        flex-direction: row;
        /* top | left and right | bottom */
        margin: 0rem 2rem 5rem;
    }
    .intro-image {
        width: 50%;
        height: 50%;
        display: flex;
        justify-content: end;
        margin: 3rem;
    }
    img {
        width: 50%;
    }
    .intro-text {
        width: 50%;
        text-align: left;
        margin-top: 3rem;
        margin-left: 0rem;
        margin-right: 6rem;
        margin-bottom: 2rem;
    
        padding-top: 2rem;
        padding-left: 1rem;
        padding-right: 8rem;
    }
    .intro-text p {
        font-size: 1.2rem;
        font-weight: 400;
    }
    .intro-text .social-icons {
        margin: 2rem 0 0;
    }
}

/* MOBILE */
@media screen and (max-width: 1200px) {
    .intro-section {
        display: block;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .intro-image {
        width: 100%;
    }
    img {
        width: 50%;
    }
    .intro-text {
        text-align: left;
    }
    .intro-text p {
        font-size: 1.2rem;
        font-weight: 200;
        margin: 2rem 2rem 0rem;
    }
    .intro-text .social-icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
}

.intro-text a {
    color: #000;
    padding: 1rem 1rem 0;
    font-size: 1.5rem;
}

.intro-text p {
    color: #000;
}