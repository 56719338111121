/* DESKTOP */
@media screen and (min-width: 900px) {
    .featured-section {
        font-size: 1.5rem;
        margin-bottom: 5rem;
    }
}

/* MOBILE */
@media screen and (max-width: 900px) {
    .featured-section {
        font-size: 1.5rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .projects-grid {
        display: block;
        justify-content: center;
    }
    
}

.featured-section .projects-grid .card-content p, h4, h6 {
    color: #000;
}

.featured-section .projects-grid .card-content h4 {
    font-family: 'Space Mono', monospace; 
    color: #000; 
    font-size: 1rem;
}

.featured-section p {
    font-size: 1rem;
}

.featured-section .tags {
    visibility: hidden;
}

/* external link icon */
.more-projects a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #000;
    text-decoration: underline;
}

.more-projects a:hover {
    color: #000;
}

i {
    margin-left: 0.5rem;
}
  