/* DESKTOP */
@media screen and (min-width: 900px) {
    .center-section {
        background: #66507F;
        /* top | left and right | bottom */
        margin: 5rem 10rem 5rem;
        padding: 5rem 10rem 3rem;
    }
    .center-section p {
        color: #fff;
        text-align: left;
        margin-top: 2rem;
        font-size: 1.2rem;
        font-weight: 400;
    }
    .my-projects-btn {
        margin-top: 2rem;
        color: #000;
    }
}

/* MOBILE */
@media screen and (max-width: 900px) {
    .center-section {
        background: #66507F;
        /* top | left and right | bottom */
        margin-top: 5rem;
    }
    .center-section p {
        color: #fff;
        text-align: left;
        margin: 2rem;
        font-size: 1.2rem;
        font-weight: 200;
    }
    .my-projects-btn {
        padding-bottom: 2rem;
        margin-top: 2rem;
        color: #000;
    }
}

.center-section h3 {
    color: #fff;
    font-size: 1.5rem;
    font-family: 'Space Mono', monospace;  
    padding-top: 1rem;
}

.center-section-text a {
    color: #F1AB42;
}

.center-section-text a:hover {
    text-decoration: underline;
}

.my-projects-btn button {
    background: #F2DCC2;
    border-radius: 0.6rem;
    height: 3rem;
    width: 8rem;
    border: none;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 1.1rem;
    font-weight: 700;
}


