/* DESKTOP */
@media screen and (min-width: 900px) {
    .card-content {
        width: 30%;
        margin: 1rem 1rem 1rem 1rem;
        display: block;
        padding: 2rem 2rem 2rem;
        /*border: 2px solid #999;*/
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        overflow-wrap: normal;
    }
}

/* MOBILE */
@media screen and (max-width: 900px) {
    .card-content {
        width: 100%;
        display: block;
        padding: 1rem 1rem 1rem;
        margin: 1rem 0 1rem;
        /*border: 2px solid #999;*/
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        overflow-wrap: normal;
    }
}

.card-content img {
    width: 100%;
    border-radius: 5px;
}

.card-content h4 {
    margin: 0.5rem 0 0;
    font-family: 'Space Mono', monospace;
    font-size: 1rem;
}

.card-content p  {
    width: 100%;
    text-align: left;
    margin: 0.5rem 0 0;
}

.projects-grid .card-content p, h4 {
    color: #fff;
}

.card-content a {
    color: #815AB4;
}

.card-content a:hover {
    text-decoration: underline;
}

.tag {
    display: flex;
    width: fit-content;
    float: left;
    padding: 0.1rem 0.4rem 0.1rem;
    margin: 0.2rem;
}

.tags {
    margin-top: 1rem;
    float: bottom;
}