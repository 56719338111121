.header.light {
    background-color: #F5F5F5;
}

.header.light a {
    color: #000;
}

.header.dark {
    background-color: #272626;
}

.header.dark a {
    color: #fff;
}

/* DESKTOP */
@media screen and (min-width: 900px) {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3rem 3rem 0rem;
        margin-left: 7rem;
        margin-right: 7rem;
        margin-bottom: 3%;
    }
    .heading {
        display: flex;
        flex-direction: row;
    }
    .sparkle-icon {
        width: 20%;
    }
    .nav-menu {
        display: flex;
    }
}

/* MOBILE */
@media screen and (max-width: 900px) {
    .header {
        display: block;
        align-items: center;
        padding-top: 3rem;
    }
    .heading {
        display: block;
        align-items: center;
    }
    .sparkle-icon {
        visibility: hidden;
        width: 0%;
        height: 0%;
    }
    .nav-menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 1rem;
    }
}

.nav-menu li {
    padding: 0rem 1rem 0rem;
}

.nav-menu li a {
    font-size: 1.2rem;
    font-weight: 600;
}