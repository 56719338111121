@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.light {
  background: #F5F5F5;
}

.dark {
  background: #272626;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

.sparkle-cursor {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>👀</text></svg>")
    16 0, auto;
}

h1,h4,p,a {
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  cursor: pointer;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32pm;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248, 217, 15);
  color: #222;
  border: 1pm solid #fff;
  font-weight: 600;
  cursor: pointer
}

.btn-light {
  background: transparent;
  color: #fff;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}

/* DESKTOP */
@media screen and (min-width: 900px) {
  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .react-pdf__Page {
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 8px;
    position: relative;
    max-height: calc(100% - 10vw);
    min-width: 288px;
    border: 0;
    background: white;
    overflow: auto;
    font: inherit;
    color: inherit;
  }
}
/* MOBILE */
@media screen and (max-width: 900px) {
  .react-pdf__Document {
    visibility: hidden;
  }
  .react-pdf__Page {
    box-shadow: 0 20px 30px 0 rgba(16, 36, 94, 0.2);
    border-radius: 5px;
    position: relative;
    border: 0;
    background: white;
    font: inherit;
    color: inherit;
    width: fit-content;
  }
}


