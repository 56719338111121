.App {
  text-align: center;
}

.App.light {
  background: #fff;
}

.App.dark {
  background: #000;
}

